<template>
  <p>
    <Field
      type="checkbox"
      v-model="rgpdAccepted"
      :value="rgpdAccepted"
      :rules="'rgpdChecked'"
      :name="'rgpd'"
      id="rgpd"
    >
      <input
        type="checkbox"
        id="rgpd"
        name="rgpd"
        v-model="rgpdAccepted"
        :value="rgpdAccepted"
        @change="$emit('rgpd', rgpdAccepted)"
      />
      <label for="rgpd"
        >Acepto la
        <a href="javascript:void(0)" @click="$emit('privacy')"
          >política de privacidad</a
        >
        y los
        <a href="javascript:void(0)" @click="$emit('terms')"
          >términos y condiciones</a
        >.</label
      >
    </Field>
  </p>

  <ErrorMessage :name="'rgpd'" />
</template>

<script>
import { Field, ErrorMessage } from 'vee-validate';

export default {
  name: 'RGPD',
  emits: ['rgpd', 'privacy', 'terms'],
  components: {
    Field,
    ErrorMessage,
  },
  data() {
    return {
      rgpdAccepted: false,
    };
  },
};
</script>
